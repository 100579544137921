@import "src/directives/loading/loading.less";

// Brand colors
@maxm-primary-M1:     #001B3C;

@maxm-secondary-M2:   #031373;
@maxm-secondary-M3:   #0F00A6;
@maxm-secondary-M4:   #1A05F7;
@maxm-accenting-M6:   #FC005C;

@maxm-accenting-M5:   #FFAB00;
@maxm-accenting-M7:   #FFA0D1;
@maxm-secondary-M13:  #3C9664;
@maxm-accenting-M8:   #FAFAFA;

@maxm-accenting-M9:   #3B3B3B;
@maxm-accenting-M10:  #707070;
@maxm-accenting-M11:  #C2C2C2;
@maxm-accenting-M12:  #E8E8E8;


@cs-info-panel-color: @maxm-accenting-M12;

/* Required for Angular UI Bootstrap */
.nav, .pagination, .carousel, .panel-title a { cursor: pointer; }

pre {
    border-color: @maxm-accenting-M12;
    background-color: transparent;
}

textarea {
    resize: vertical;
}

.round {
    border-radius: 4px;
}


.atw-radio-list > .mm-radio {
    margin-right: 5px;
}

.table > tbody.thin-top-border, tbody.thin-top-border > tr {
    border-top-width: thin;
}

.mm-message {
    padding-left: 15px;
    padding-right: 15px;
}

.navbar-login{
    margin-bottom: 0px;
}

.containerStyle {
    padding-bottom: 20px;
}

.btn {
    padding: 6px 12px;
}

.btn-sm {
    padding: 5px 10px;
}

.btn-xs {
    padding: 1px 5px;
}

.btn-default {
    background-color: white;
}

a {
    color: @maxm-secondary-M2;
}

.btn-link{
    color: #004478;
}

.atw-well {
    max-height: 250px; overflow-y: scroll;
}

.linkStyle {
    color: @maxm-secondary-M2;
}

.mm-logo-svg {
    height: 34px;
    width: 147px;
}

.company-heading {
    color: @maxm-secondary-M2;
}


.cs-left-panel {
    background: @cs-info-panel-color;
    padding: 9px;
}
.cs-left-panel h3 {
    margin-top: 9px;
    margin-bottom: 9px;
}
.cs-center-panel {
    background: white;
    padding: 9px;
}

.cs-right-panel {
    background:  @cs-info-panel-color;
    padding: 9px;
    margin-bottom: 9px;
}
.cs-right-panel h3 {
    margin-top: 0;
    margin-bottom: 9px;
}

.cs-case-details-separator {
    margin: 5px 0;
}

.cs-cases-card{
    cursor: pointer;
    margin-bottom: 9px;
}
